
.post-card {
  background-color: $black;
  .post-type {
    font-size: 14px;
    text-decoration: underline;
  }
  .post-title {
    font-size: 22px;
    line-height: 1.2;
    min-height: 100px;
  }
  .post-excerpt {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }
}

#page-post {
  .title {
    background-color: $white;
    padding: 50px 50px 35px 30px;
    margin-right: -80px;
    h1 {
      margin-bottom: 15px !important;
    }
    .date {
      font-size: 18px;
      font-weight: 200;
    }
  }
  .description {
    margin-top: 100px;
    p {
      font-size: 18px;
      font-weight: 200;
    }
  }
}

#post-pagination {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 80px;
  a:hover {
    text-decoration: none;
    i {
      transition: all 0.4s;
      animation-duration: 1s !important;
    }
    .fa-arrow-left {
      margin-left: -15px;
    }
    .fa-arrow-right {
      margin-right: -15px;
    }
  }
  .fa-arrow-left, .fa-arrow-right {
    font-size: 30px;
    transition: all 0.4s;
  }
  .fa-grid-2 {
    font-size: 50px;
    animation-duration: 0s !important;
  }
}

.agency-post, .session-post {
  margin-bottom: 30px;
  h3 {
    font-size: 24px !important;
  }
  p {
    font-size: 16px;
    font-weight: 200;
    line-height: 1.5;
  }
  a {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.6s;
    margin-bottom: 10px;
    i {
      margin-left: 10px;
      font-size: 16px;
    }
    &:hover {
      text-decoration: none;
      margin-right: -20px;
      transition: all 0.6s;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .post-card .post-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {

  #page-post {
    .title {
      padding: 20px 0 40px;
      margin-right: 0;
      .date {
        font-size: 18px;
        font-weight: 200;
      }
    }
    .description {
      margin-top: 60px;
    }
  }

  .post-card .post-title {
    min-height: 120px;
  }

  #post-pagination {
    margin-top: 30px;
  }

}

@media only screen and (max-width: 640px) {

  .post-card {
    .post-title {
      min-height: 72px;
      margin-bottom: 30px;
    }
    .post-excerpt {
      font-size: 13px;
    }
  }

  #page-post {
    .title {
      padding: 20px 0;
      margin-right: 0;
      h1 {
        font-size: 30px;
        margin-bottom: 10px;
      }
    }
    .description {
      margin-top: 40px;
      text-align: justify;
    }
  }

  #post-pagination {
    margin-top: 10px;
    .fa-grid-2 {
      font-size: 40px;
    }
    a span {
      display: none;
    }
    a:hover {
      .fa-arrow-left {
        margin-left: -5px;
      }
      .fa-arrow-right {
        margin-right: -5px;
      }
    }
  }


  .agency-post, .session-post {
    h3 {
      font-size: 16px !important;
      margin-bottom: 0;
    }
    p {
      font-size: 12px;
      line-height: 1.4;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .uk-width-2-3 {
      padding-left: 15px;
    }
  }

}