
#header {
  background-color: $white;
  padding: 40px 0;
  .navbar-item {
    a {
      text-transform: uppercase;
      color: $black !important;
      font-size: 24px;
      font-weight: 900;
      transition: width .6s;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 3px;
        bottom: -2px;
        left: 0;
        background-color: $black;
      }
    }
    &:hover a, &.navbar-item--active a {
      position: relative;
      &:after {
        width: 100%;
        transition: width .6s;
      }
    }
  }
  .uk-navbar-nav > li > a {
    min-height: auto;
  }
  &.uk-active {
    padding: 15px 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    svg {
      height: 70px;
      width: 110px;
    }
  }
}


#burger {
  height: 30px;
  width: 35px;
  position: relative;
  i {
    transition: all 0.3s;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    background: $black;
    border-radius: 10%;
    &::before, &::after {
      position: absolute;
      left: 50%;
      width: 100%;
      height: 100%;
      background: inherit;
      content: "";
      transition: width 0.3s;
      transform: translate(-50%);
      border-radius: 10%;
    }
    &::before {
      top: -11px;
    }
    &::after {
      top: 11px;
    }
  }
  &:hover i::before, &:hover i::after {
    width: 70%;
  }
}

#menu-fullscreen {
  background-color: $black;
  .uk-modal-dialog {
    background-color: $black;
  }
  > * {
    color: $white !important;
  }
  svg {
    margin: 30px auto;
    fill: $white;
  }
  .uk-nav-default > li > a {
    color: $white;
  }
  .uk-nav {
    li {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      margin-bottom: 10px;
      &.small-nav {
        font-size: 14px;
        font-weight: 200;
        text-transform: none;
      }
      a {
        position: relative;
        display: inline;
      }
      &.active {
        a:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 3px;
          bottom: -1px;
          left: 0;
          background-color: $white;
        }
      }
    }
  }
  .social-medias {
    i {
      color: $white;
      font-size: 35px;
      margin: 0 20px;
      line-height: 54px;
      transition: transform .2s;
      &:hover {
        transition: transform .2s;
        transform: scale(1.3);
      }
    }
  }
  .newsletter {
    margin-bottom: 50px;
    h3 {
      text-align: center;
      color: $white;
      font-size: 18px;
    }
    input {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid $white;
      width: 100%;
      line-height: 2;
      color: $white;
      &::placeholder {
        color: $white;
      }
    }
    .border-btn {
      margin: 40px auto 0;
      display: block;
    }
  }

  .uk-modal-close {
    position: absolute;
    top: 25px;
    right: 15px;
    cursor: pointer;
    z-index: 100000;
    line-height: 0;
    height: 40px;
    width: 40px;
    transition: all 0.3s;
    &::before, &::after {
      position: absolute;
      top: 25px;
      right: 0;
      content: "";
      width: 32px;
      height: 3px;
      background: $white;
      transform-origin: 50% 50%;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
    &:hover::after, &:hover::before {
      transform: none;
    }
  }
}