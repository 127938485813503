@import '~uikit';
@import '_vars';
@import '_font';
@import '_header';
@import '_footer';
@import '_event';
@import '_post';

*, a, h1, h2, h3, h4, h5 {
    font-family: 'Inter', 'sans-serif';
    color: $black;
}

h1, h2, h3, h4, h5 {
    text-transform: uppercase;
    font-weight: 900;
}

a:hover {
    color: inherit;
}

.title-large {
    font-size: 60px;
    margin-bottom: 0;
}

.title-xlarge {
    font-size: 80px;
    margin-bottom: 0;
}

.border-btn {
    text-transform: uppercase;
    border: 2px solid $black;
    color: $black;
    font-size: 16px;
    font-weight: 900;
    padding: 15px 20px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.6s;
    &:hover {
        text-decoration: none;
        background-color: $black;
        transition: all 0.6s;
        color: $white !important;
        > *, i {
            color: $white !important;
        }
    }
    &.border-btn--white {
        border: 2px solid $white;
        color: #fff;
        &:hover {
            background-color: $white;
            color: $black !important;
            > *, i {
                color: $black !important;
            }
        }
    }
    &.border-btn--small {
        font-size: 14px;
        padding: 2px 15px;
    }
    &.border-btn--large {
        border: 3px solid $black;
        font-size: 24px;
        padding: 20px 40px;
        i {
            font-size: 55px;
        }
    }
}


.card-details {
    padding: 30px 20px 25px;
    * {
        color: $white;
    }
}


.home-section, .other-section {
    margin-bottom: 80px;
    margin-top: 50px;
    a:hover {
        text-decoration: none;
        color: inherit;
    }
    .section-titles {
        h2 {
            margin-top: 0;
            margin-bottom: 30px;
        }
        h3 {
            font-size: 24px;
            font-weight: 200 !important;
            margin-bottom: 40px;
            text-transform: inherit;
        }
        a {
            font-size: 18px;
            font-weight: 900;
            i {
                font-size: 80px;
                margin-left: 30px;
                transition: all 0.6s;
            }
            &:hover {
                i {
                    margin-left: 60px;
                    transition: all 0.6s;
                }
            }
        }
    }
}

.uk-pagination {
    li {
        font-size: 24px;
        font-weight: 400;
        color: $black;
    }
    .fa-arrow-left {
        margin-right: 10px;
    }
    .fa-arrow-right {
        margin-left: 10px;
    }
}
.uk-pagination > * > *, .uk-pagination > * > :hover, .uk-pagination .uk-active > *, .uk-pagination > .uk-disabled > * {
    color: $black;
}
.uk-pagination .uk-active > * {
    font-weight: 900;
    padding: 5px 7px;
}
.uk-pagination > * > * {
    padding: 5px;
}

iframe {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    aspect-ratio: 1920 / 1080;
}


.uk-slider .credit {
    bottom: 5px;
    right: 10px;
    color: $white;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 200;
}


@media only screen and (min-width: 1450px) {

}

@media only screen and (max-width: 1200px) {
    #header {
        padding: 15px 0;
        svg {
            height: 70px;
            width: 110px;
        }
        .main-navbar-item a {
            font-size: 20px;
        }
        .uk-navbar-nav {
            gap: 30px;
        }
    }
}

@media only screen and (min-width: 960px) {
    #header .uk-navbar-nav {
        gap: 45px;
    }
}

@media only screen and (max-width: 960px) {
    .title-large {
        font-size: 35px;
    }
    .title-xlarge {
        font-size: 45px;
    }
    #passed-table {
        tr {
            position: relative;
        }
        .actions {
            position: absolute;
            top: 10px;
            right: 0;
        }
    }
    .home-section, .other-section {
        margin-bottom: 50px !important;
        .section-titles h2 {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 640px) {
    .border-btn {
        &.border-btn--large {
            font-size: 18px;
            padding: 10px 20px;
            i {
                font-size: 35px;
            }
        }
    }
}