
#footer-top {
  background-color: $black;
  padding: 90px 0;
  margin-top: 150px;
  h3 {
    color: $white;
    font-size: 30px;
    margin-bottom: 30px;
  }
  #social-medias i {
    color: $white;
    font-size: 35px;
    margin-right: 35px;
    line-height: 54px;
    transition: transform .2s;
    &:hover {
      transition: transform .2s;
      transform: scale(1.3);
    }
  }
}

#newsletter-form {
  input {
    background: none;
    border: none;
    border-bottom: 2px solid $white;
    margin-right: 40px;
    width: 300px;
    &::placeholder {
      color: $white;
    }
  }
}

#footer {
  margin-top: 5px;
  padding: 50px 0 30px;
  background-color: $black;
  *, a {
    color: $white;
  }
  a {
    font-size: 16px;
    font-weight: 300;
  }
  span {
    font-size: 12px;
    font-weight: 200;
    a {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 960px) {
  #footer-top {
    padding: 60px 0;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 640px) {
  #footer {
    padding: 30px 0;
  }
  #footer-top {
    h3 {
      font-size: 24px;
      margin-bottom: 30px;
      text-align: center;
    }
    #newsletter-form {
      justify-content: center;
      input {
        margin-right: 0;
        line-height: 2;
        width: 100%;
      }
      .border-btn {
        margin: 30px auto 0;
      }
    }
    #social-medias {
      justify-content: center;
      i {
        margin: 0 20px;
      }
    }
  }
}