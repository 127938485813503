
.event-card {
  background-color: $black;
  .event-tag {
    background-color: $black;
    color: $white;
    padding: 3px 10px;
    top: 23px;
    right: 20px;
    font-size: 16px;
    font-weight: 800;
  }
  .event-date {
    font-size: 16px;
  }
  .event-hour {
    font-size: 16px;
    font-weight: 200;
  }
  .event-title {
    font-size: 26px;
    line-height: 1.2;
    height: 70px;
    margin-bottom: 50px;
    span {
      display: block;
      font-size: 16px;
    }
  }
  .event-place {
    font-size: 16px;
    span:first-of-type {
      font-weight: 900;
    }
    span:last-of-type {
      font-weight: 300;
    }
  }
}

#calendar {
  margin-bottom: 150px;
  .events {
    margin-top: 50px;
  }
}

.passed-event-btn {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 900;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
  i {
    font-size: 40px;
    margin-left: 20px;
  }
}

#passed-table {
  margin-bottom: 60px;
  tr {
    text-transform: uppercase;
    border-top: 3px solid $black;
    &:last-of-type {
      border-bottom: 3px solid $black;
    }
  }
  .date, .place {
    font-size: 18px;
    font-weight: 700;
  }
  .title {
    font-size: 22px;
    line-height: 1.2;
    span {
      font-size: 16px;
      display: block;
    }
  }
  .city {
    font-size: 18px;
    font-weight: 300;
  }
  a i {
    font-size: 35px;
    animation-duration: 0s !important;
  }
  a:hover {
    text-decoration: none;
    i {
      animation-duration: 1s !important;
    }
  }
}

#page-session {
  .other-section {
    margin-top: 100px;
  }
}


#session-recap-fixed {
  .date {
    font-size: 24px;
    font-weight: 900;
  }
  .tag {
    font-size: 16px;
    font-weight: 900;
    background-color: $black;
    color: $white;
    padding: 5px 10px;
  }
  h1 {
    line-height: 1.1;
    margin-top: 50px;
    margin-bottom: 50px;
    span {
      display: block;
      font-size: 45px;
    }
  }
  .place {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 30px;
    span {
      display: block;
      font-weight: 400;
    }
  }
  .hour {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
  }
}

#session-desc {
  .description {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 200;
    line-height: 1.5;
  }
  hr {
    border-top: 5px solid $black;
  }
  h3 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
  h4 {
    font-size: 24px;
  }
  .place {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
  }
  #social-medias {
    i {
      font-size: 24px;
      margin-right: 20px;
    }
  }
}

.other-section {
  margin-top: 100px;
  margin-bottom: 100px;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: #fff;
}

#gallery {
  background-color: $black;
  padding: 70px 0;
  margin-top: -120px;
  margin-bottom: 140px;
  .gallery-title {
    margin-bottom: 100px;
    > * {
      color: $white !important;
    }
  }
  h2 {
    font-size: 50px;
  }
  h3 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0;
    text-transform: initial;
  }
  .uk-slider {
    margin-right: -300px;
  }
}

#gallery-post-image {
  border: 50px solid $white;
}

@media only screen and (max-width: 1450px) {

  .event-card {
    .event-title {
      font-size: 22px;
      margin-bottom: 40px;
    }
  }

  #gallery {
    padding: 70px 0;
    margin-top: -100px;
    .gallery-title {
      margin-bottom: 100px;
    }
    h2 {
      font-size: 50px;
    }
    .uk-slider {
      margin-right: -170px;
    }
  }

}


@media only screen and (max-width: 1200px) {

  #gallery {
    padding: 70px 0;
    margin-top: -60px;
    .gallery-title {
      margin-bottom: 100px;
    }
    .uk-slider {
      margin-right: -100px;
    }
  }

  #gallery-post-image {
    border: 30px solid $white;
  }

  #page-gallery {
    #session-recap-fixed {
      h1 {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 60px;
      }
    }
    .border-btn {
      font-size: 20px;
      padding: 10px 25px;
      i {
        margin-left: 30px !important;
      }
    }
  }

}


@media only screen and (max-width: 960px) {

  #session-recap-fixed {
    .date {
      font-size: 16px;
    }
    .tag {
      font-size: 12px;
    }
    h1 {
      margin-top: 20px;
      margin-bottom: 20px;
      span {
        font-size: 25px;
      }
    }
    .place {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .hour {
      font-size: 16px;
      margin-bottom: 30px;
    }
    .border-btn {
      margin-bottom: 10px !important;
    }
  }

  #session-desc {
    .description p {
      text-align: justify;
    }
  }

  #passed-table {
    .city {
      margin-bottom: 15px;
    }
  }

  #gallery {
    margin-top: 50px;
    margin-bottom: 70px;
    .gallery-title {
      margin-bottom: 60px;
    }
  }

  #gallery-post-image {
    border: none;
  }

}



@media only screen and (max-width: 640px) {

  .event-card {
    .event-title {
      font-size: 22px;
      height: 75px;
      margin-bottom: 30px;
      span {
        font-size: 14px;
      }
    }
    .event-place {
      font-size: 14px;
    }
  }

  #calendar {
    margin-bottom: 80px;
    .events {
      margin-top: 15px;
    }
  }

  .passed-event-btn {
    font-size: 11px;
    i {
      font-size: 27px;
    }
  }

  #page-gallery #session-recap-fixed h1 {
    font-size: 45px;
    margin-bottom: 20px;
  }

  #gallery {
    padding-top: 50px;
    .gallery-title {
      margin-bottom: 30px;
    }
    h2 {
      font-size: 30px;
    }
  }
}